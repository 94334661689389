import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
  color: white;
  margin-top: 100px;
  padding: 0 20px;
  line-height: 1.5;
  font-family: Arial, sans-serif;
  text-align: left;
`;

const PolicyTitle = styled.h1`
  font-size: 2em;
  margin-bottom: 1em;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin: 1em 0;
`;

const SubsectionTitle = styled.h3`
  font-size: 1.3em;
  margin: 1em 0;
`;

const PrivacyPolicy = () => {

  return (
    <PolicyContainer>
      <PolicyTitle>Racct Inc. Privacy Policy</PolicyTitle> {/* Updated title to include "Inc." */}
      <p><strong>Last Updated: September 5, 2023</strong></p> {/* Updated the last updated date */}

      <p>Racct Inc. ("we," "us," "our") provides a platform to support sellers of used goods online with auctions. Your privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, share, and protect your information when you use our services, including but not limited to our website, mobile applications, and any other online services linked to this Privacy Policy (collectively, the "Services").</p>

      <SectionTitle>1. Information We Collect</SectionTitle>

      <p>When you use our Services, we collect the following types of information:</p>

      <SubsectionTitle>1.1 Information You Provide</SubsectionTitle>

      <p>This includes information you enter when creating or updating your Racct.com account, such as your name, email address, phone number, login name and password, address, payment or banking information, government identification numbers, birth date, and profile picture.</p>

      <SubsectionTitle>1.2 Information from Third Party Services</SubsectionTitle>

      <p>If you choose to register or log in to our Services using a third-party service like Facebook, we may receive information from these services, such as your name, profile picture, age range, language, email address, and friend list. The information we receive depends on the privacy settings you have with the third-party service.</p>

      <SectionTitle>2. Use of Information</SectionTitle>

      <p>We use the information we collect for various purposes, including:</p>

      <ul>
        <li>Providing and improving our Services.</li>
        <li>Personalizing the Services for you.</li>
        <li>Processing transactions.</li>
        <li>Communicating with you.</li>
        <li>Research and analytics.</li>
        <li>Enforcing our Terms of Service and complying with applicable laws.</li>
      </ul>

      <SectionTitle>3. Sharing and Disclosure of Information</SectionTitle>

      <p>We do not sell or share your personal information with third parties for their marketing purposes. We may share your information in the following circumstances:</p>

      <ul>
        <li>With your consent.</li>
        <li>With third-party service providers and partners who assist us in providing the Services.</li>
        <li>In response to legal process or a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process.</li>
        <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Racct.com or others.</li>
        <li>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company.</li>
      </ul>

      <SectionTitle>4. Protection of Information</SectionTitle>

      <p>We implement a variety of security measures to maintain the safety of your personal information. We use encryption technology for information sent and received by us. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.</p>

      <SectionTitle>5. Your Choices</SectionTitle>

      <p>You may update, correct or delete information about you at any time by logging into your online account and modifying your information or by emailing us at support@racct.com. You may also opt out of receiving promotional emails from us by following the instructions in those emails.</p>

      <SectionTitle>6. Changes to This Privacy Policy</SectionTitle>

      <p>Racct.com may modify or update this Privacy Policy from time to time, so please review it periodically. We may provide you additional forms of notice of modifications or updates as appropriate under the circumstances.</p>

      <SectionTitle>7. Contact Us</SectionTitle>

      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@racct.com</p>

      <p>By using our Services, you agree to the terms of this Privacy Policy. You shouldn't use the Services if you don't agree with this Privacy Policy or any other agreement that governs your use of the Services.</p>
    </PolicyContainer>
  )
}

export default PrivacyPolicy;
