import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"; 
import { PaymentForm } from "../../components/PaymentForm";

function Payments() {

  let [proposedValue, setProposedValue] = useState('')



  // const [clientToken, setClientToken] = useState(null);
  // //Replace Client ID and App Secret


  // // const clientId = "<test>";

  // const clientId = "AdWJNN0M7MvRiwTH-Xau6DxFnDTj_JiB6jwkcdHDoEQKmtq6e-rkZr4H7WkS1UdVLD3oQbyOmsUjCa_V"

  // useEffect(() => {
  //   (async () => {

  //     // const response = await fetch("/api/token", {
  //     //   method: "post",
  //     // });

  //     const response = await fetch("https://us-central1-uchiprereg.cloudfunctions.net/server/paypal-checkout", {
  //       method: "get",
  //     });

  //     // const response = await fetch("https://us-central1-uchiprereg.cloudfunctions.net/server/api/paypal-orders", {
  //     //   method: "post",
  //     // });

  //     const client_token = await response.json();
  //     console.log(client_token)
  //     setClientToken(client_token);
  //   })();
  // }, []);


  const [step, setStep] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [account, setAccount] = useState('');

  const handleSelection = (method) => {
      setPaymentMethod(method);
      setStep(1);
  }

  const handleSubmit = (event) => {
      event.preventDefault();
      if (!account) {
          alert('Please enter your account information.');
          return;
      }
      alert(`Your ${paymentMethod} account (${account}) has been saved.`);
  };


  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>

      
        {/* <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p> */}
        

        {/* <div style = {{color:'white', textAlign:'left'}}>
            <h2 style={{fontWeight:'bold'}}>Payment Information</h2>
            <div style={{fontSize:'1.2rem'}}>How do you want to be paid?</div>
            {step === 0 ? (
                <div style={{marginTop:'1rem'}}>
                    <div className="btn btn-primary-paypal" onClick={() => handleSelection('paypal')}><p style={{display:'inline', color:'#003087'}}>Pay</p><p style={{display:'inline', color:'#0070e0'}}>Pal</p></div>
                    <div style={{marginLeft:'0.5rem'}} className="btn btn-primary-venmo" onClick={() => handleSelection('venmo')}>Venmo</div>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                <div className="" onClick={() => setStep(0)} style={{fontSize:'1.5rem', fontWeight:'bolder', cursor:'pointer', display:'inline-block'}}>&larr;</div>
                    <div>
                        <label style={{fontWeight:'bold'}}>{paymentMethod === 'paypal' ? `Email:` : `Username:`}&nbsp;&nbsp;{paymentMethod === 'paypal'? ``:`@`}&nbsp;</label>
                        <input type="text" value={account} onChange={e => setAccount(e.target.value)} />
                    </div>
                    <div>
                        <div className="btn btn-primary" style={{marginTop:'1rem'}}>Save</div>
                    </div>
                </form>
            )}
        </div> */}











        <div style={{color:'white', height:'10rem'}}>
        </div>


        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>


        <PayPalScriptProvider 
        options={{ 
          // "client-id": "AdWJNN0M7MvRiwTH-Xau6DxFnDTj_JiB6jwkcdHDoEQKmtq6e-rkZr4H7WkS1UdVLD3oQbyOmsUjCa_V" 
          "client-id": "test" 
        }}> 
              <PayPalButtons
              style = {{ layout:'vertical' }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: proposedValue,
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        const name = details.payer.name.given_name;
                        alert(`Transaction completed by ${name}`);
                    });
                }}
            />
        </PayPalScriptProvider>
        {/* {clientToken ? (
        <PaymentForm clientToken={clientToken} clientID={clientId} />
        ) : (<h4></h4>) } */}

        </Row>
      </Container>
    </Container>
  );
}

export default Payments;
