import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import FirebaseAuth from './SignUpLogin/Signup'
import { Container, Row, Col, Card, Button, Carousel, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; // import toast from react-toastify
import Particle from "./Particle";
import { deleteDoc } from 'firebase/firestore'; // for deleteDoc
import { getAuth, deleteUser } from 'firebase/auth'; // for getAuth and deleteUser
import { auth, db, storage } from '../index'; // Modify path as needed
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from 'firebase/storage';
import { collection, getDocs, query, getDoc, where, onSnapshot, doc, updateDoc, addDoc, orderBy, setDoc } from 'firebase/firestore';

const DeleteUserData = () => {
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete your account and all associated data? This action is irreversible.")) {
      try {
        const user = auth.currentUser;
        
        if (user) {
          // Delete user data in Firestore
          const userDocRef = doc(db, 'users', user.uid);
          await deleteDoc(userDocRef);
          
          // Delete the user's Authentication data
          const auth = getAuth();
          await deleteUser(user);

          setIsDeleted(true);
          toast('Your data has been deleted.'); // toast notification
        }
      } catch (error) {
        console.error("Failed to delete user data", error);
      }
    }
  }

  return (
    <div style={{ marginTop: '100px', color: 'white', minHeight: 'calc(100vh - 218px)' }}>
    <Particle />
    {!isDeleted && <>
      <h1>If you click the delete button, all of your data will be permanently deleted.</h1>
      <Button variant="primary" onClick={handleDelete}>Delete my data</Button>
    </>}
    <ToastContainer /> {/* ToastContainer component */}
  </div>
  );
}
// Constants - replace these placeholders as needed
const created = new Date();
const getDirection = 90; // replace with actual logic or data
const getColor1 = 255, getColor2 = 255, getColor3 = 255, getShade1 = 1;
const getColor4 = 0, getColor5 = 0, getColor6 = 0, getShade2 = 0;

const UserAuth = () => {
  const [user, loading] = useAuthState(auth);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(async (currentUser) => {
      console.log('Checking auth state...');
      setIsAuthenticated(!!currentUser);
      console.log('Is user authenticated? ', !!currentUser);

      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          console.log('Existing user...');
          
        } else {
          console.log('New user...');
          await setDoc(userDocRef, {
            displayName: currentUser.displayName,
            email: currentUser.email,
            created: new Date(),
            colorProfile: `linear-gradient(${getDirection}deg, rgba(${getColor1}, ${getColor2}, ${getColor3}, ${getShade1}), rgba(${getColor4}, ${getColor5}, ${getColor6}, ${getShade2}))`,
            photoURL: currentUser.photoURL,
            receiveMethod: '',
            receiveAccount: '',
            payMethod: '',
            cardLast4: '',
            paypalPaymentToken: '',
            paypalCustomerId: '',
            photoPosition: { x: '50%', y: '50%' },
          });
        }
      }
    });

    return () => unregisterAuthObserver();
  }, []);
  if (loading) {
    return <div>Loading...</div>; // or a loading spinner
  }

  if (!isAuthenticated) {
    return (
      <div>
        <FirebaseAuth />
      </div>
    );
  }
if (isAuthenticated)
  return (
    <div>
      <DeleteUserData />
    </div>
  );
}

export default UserAuth;
