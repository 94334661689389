import React, { useState, useEffect } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './index'
import 'bootstrap/dist/css/bootstrap.min.css';
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Payments from "./components/Payments/Payments";
import FirebaseAuth from "./components/SignUpLogin/Account"
import About from "./components/About/About";
import SellerPage from "./components/Sell"
import BuyerPage from "./components/Buying/Buy";
import PrivacyPolicy from "./components/Privacy";
import UserAuth from "./components/Delete";
import Support from "./components/Support"
import TermsOfService from "./components/Terms"
import Details from "./components/Buying/Details"
import WaitlistSignup from "./components/Waitlist"
import Bid from "./components/Buying/Bid"
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import { HelmetProvider } from 'react-helmet-async';
import Resume from "./components/Resume/ResumeNew";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Uploader from "./components/Uploader/Uploader";
import Profile from "./components/Profile/Profile";
import Error from "./components/Buying/Error";
import TakeToAppstore from "./components/TakeToAppstore";
import AdminDashboard from "./components/AdminDashboard";
import HomeLaunch from "./components/Home/HomeLaunch";
import NavBar2 from "./components/NavBar2";




function App() {
  
  const [load, upadateLoad] = useState(true);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  const currentPath = window.location.pathname;

  return (
  <HelmetProvider>
    <Router>
        <Preloader load={load} />
        <div className="App" id={load ? "no-scroll" : "scroll"} style={{zIndex:'-1', overflowX: "hidden"}}>




          {/* <Navbar /> */}
          <NavBar2 />


          <ScrollToTop />
          <Routes>


          <Route path="/" element={<HomeLaunch />} />






            {/* <Route path="/" element={<Home />} />
            <Route path="/uploader" element={<Uploader />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/waitlist" element={<WaitlistSignup />} />
            <Route path="/project" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/buy/:id" element={<Details />} />
            <Route path="/buy/bid/:id" element={<Bid />} />
            <Route path="/error/:id" element={<Error />} />
            <Route path="/support" element={<Support />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/account" element={<FirebaseAuth/>} />
            <Route path="/delete-user-data" element={<UserAuth/>} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/buy" element={<BuyerPage />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/sell" element={<SellerPage />} />
            <Route path="/take-to-appstore" element={<TakeToAppstore />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="*" element={<Navigate to="/"/>} /> */}

        </Routes>
        {/* {!(currentPath === "/admin-messages" || currentPath === "/admin-dashboard") && <Footer />} */}
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
