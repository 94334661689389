import React from "react";
import Typewriter from "typewriter-effect";

function Type2() {
  return (
    <Typewriter
      options={{
        strings: [
          "Air Jordan 1 Low Size 12",
          "Kitchenaid Mixer",
          "Roomba",
          "iPad Air 2",
          "Sony A7III Camera",
          "Lego Star Wars Millennium Falcon",
          "Taylor Acoustic Guitar",
          "Nespresso Vertuo Coffee Machine",
          "Nike Running Shorts Women's Medium",
          "Samsung Galaxy S23",
          "Dyson V15 Stick Vacuum",
          "The Great Gatsby - Hardcover",
          "Apple Watch Series 7",
          "Le Creuset Dutch Oven 5.5qt",
          "Fender Stratocaster Electric Guitar",
          "Adobe Photoshop Subscription",
          "Bose QuietComfort 35 II",
          "Osprey Atmos 65L Backpack",
          "Ray-Ban Aviator Sunglasses",
          "Razer DeathAdder Elite Gaming Mouse",
          "Tom Ford Noir Eau De Parfum 100ml",
          "Oculus Quest 2 VR Headset",
          "Fitbit Charge 5",
          "Microsoft Surface Pro 9",
          "Blue Yeti USB Microphone",
          "Nintendo Switch Pro",
          "GoPro HERO10 Black",
          "Peloton Exercise Bike",
          "YETI Rambler 20 oz Tumbler",
          "Kindle Paperwhite",
          "Tiffany & Co. Heart Pendant Necklace",
          "Breville Barista Express Espresso Machine",
          "Beats Studio Buds",
          "Instant Pot Duo 7-in-1 Electric Pressure Cooker",
          "North Face Thermoball Eco Jacket Men's Large",
          "Calvin Klein Euphoria Eau De Parfum 50ml",
          "Logitech C922x Pro Stream Webcam",
          "Lululemon Align Leggings Women's Size 6",
          "SONOS One (Gen 2) Smart Speaker",
          "Harry Potter Box Set - Hardcover",
          "Nike Air Max 97 Men's Size 10",
          "Herman Miller Aeron Office Chair",
          "Canon EF 50mm f/1.8 STM Lens",
          "Tarte Shape Tape Concealer",
          "UE Boom 3 Portable Bluetooth Speaker",
          "Rolex Submariner Watch",
          "Anker PowerCore 26800 Portable Charger",
          "KitchenAid 5-Quart Stand Mixer Attachments",
          "DJI Mavic Air 2 Drone",
          "UGG Women's Classic Short Boot Size 8"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type2;
