import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
  color: white;
  margin-top: 100px;
  padding: 0 20px;
  line-height: 1.5;
  font-family: Arial, sans-serif;
  text-align: left;
`;

const PolicyTitle = styled.h1`
  font-size: 2em;
  margin-bottom: 1em;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin: 1em 0;
`;

const SubsectionTitle = styled.h3`
  font-size: 1.3em;
  margin: 1em 0;
`;

const TermsOfService = () => {
  return (
    <PolicyContainer>
      <PolicyTitle>Racct Terms of Service</PolicyTitle>
      <p><strong>Last Updated: July 29, 2023</strong></p>

      <p>These Terms of Service ("Terms") govern your use of the website, services, and applications (collectively, "Services") provided by Racct.com ("we", "us", "our" or "Racct.com"). Please read these Terms carefully before using our Services. By accessing and using our Services, you agree to be bound by these Terms.</p>

      <SectionTitle>1. Facebook Login Flow</SectionTitle>

      <p>Racct.com provides the option to log into our Services using Facebook Login. This is an optional feature provided for the convenience of our users. We only collect necessary information for the login process and do not collect or store any other personal data from your Facebook account without your explicit consent. We adhere to Facebook’s data privacy policy and terms of service in using this login flow.</p>

      <SectionTitle>2. Account</SectionTitle>

      <p>To use our Services, you may need to create an account with us. You are responsible for protecting your account from unauthorized access. If you believe that your account has been compromised, please notify us immediately.</p>

      <SectionTitle>3. Your Responsibilities</SectionTitle>

      <p>You are responsible for all activities that occur under your account. You agree to comply with all applicable laws, rules, and regulations, and you may not violate the rights of others. You may not use our Services to send unsolicited communications or for any illegal or unethical purposes.</p>

      <SectionTitle>4. Content</SectionTitle>

      <p>You retain ownership of all content that you post to Racct.com. However, by posting content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute your content within our Services.</p>

      <SectionTitle>5. Prohibited Items</SectionTitle>

      <p>We prohibit the sale of certain items on Racct.com, including, but not limited to, illegal items, counterfeit goods, and stolen goods. Please refer to our Prohibited Items Policy for more information.</p>

      <SectionTitle>6. Third-party Services</SectionTitle>

      <p>Our Services may contain links to third-party websites or services that are not owned or controlled by Racct.com. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>

      <SectionTitle>7. Limitation of Liability</SectionTitle>

      <p>Racct.com shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use our Services.</p>

      <SectionTitle>8. Changes to the Terms</SectionTitle>

      <p>We may modify these Terms at any time. If we do so, we will let you know either by posting the modified Terms on the site or through other communications. It's important that you review the Terms whenever we modify them because continuing to use the Services after we have posted modified Terms on the site indicates to us that you agree to be bound by the modified Terms.</p>

      <SectionTitle>9. Contact Us</SectionTitle>

      <p>If you have any questions about these Terms, please contact us at racctteam@gmail.com.</p>

      <p>By using our Services, you are agreeing to these terms. Please read them carefully. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.</p>
    </PolicyContainer>
  )
}

export default TermsOfService;
