import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function TakeToAppstore() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    // Try to open the App Store link
    const externalRedirect = () => {
      window.location.href = "http://itunes.apple.com/app/id6464238559";
    }

    // Handle a potential decline of the prompt
    const internalRedirect = () => {
      navigate('/');
    }

    try {
      externalRedirect();
      setTimeout(internalRedirect, 2000);
    } catch (error) {
      console.error("Error during redirection:", error);
      internalRedirect();
    }
  };

  useEffect(handleRedirect, [navigate]);

  return null;
}

export default TakeToAppstore;
