import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";
import { getAuth, RecaptchaVerifier } from 'firebase/auth'; // Importing getAuth
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { hydrate, render } from "react-dom";



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDNW3AHlEnUSnc55qfdjU_r5GvkfMhznRc",
  authDomain: "racct-mobile-app.firebaseapp.com",
  projectId: "racct-mobile-app",
  storageBucket: "racct-mobile-app.appspot.com",
  messagingSenderId: "253529922320",
  appId: "1:253529922320:web:676c3ba46276c51d302395",
  measurementId: "G-G4D95Z650R"
};

// Initialize Firebase
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const auth = getAuth(app); // Initializing auth
const db = getFirestore(app);
const storage = getStorage(app)
const analytics = getAnalytics(app);
const rootElement = document.getElementById("root");


if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { auth, db, storage }